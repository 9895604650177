import Modal from 'react-modal';
import { Icon } from 'tabler-react';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Upload from 'src/components/Common/Upload/Upload/Upload';

const UploadReportModal = ({ modalIsOpen, onModalClose }) => {
    const handleModalClose = useCallback(() => {
        onModalClose();
    }, [onModalClose]);

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />
            <Upload
                url={'/admin-payment/settlement-reports/upload'}
                fileParam='file'
                params={{}}
                onClose={onModalClose}
                onComplete={() => {
                }}
            />
        </Modal>
    );
};

UploadReportModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
};

export default UploadReportModal;
