import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Button, Card, Dimmer } from 'tabler-react';
import { createApiClient } from 'src/services/api-client';
import ApiError from '../../../Common/ApiError';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';
import { download } from 'src/services/download';

class CreateOutgoingTransaction extends Component {
    constructor (props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            transaction_number: '',
        };

        this.api = createApiClient();

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange (event) {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }

    async onFormSubmit (event) {
        this.setState({
            isLoading: true,
        });

        event.preventDefault();

        const { transaction_number } = this.state;

        this.api
            .post(
                '/admin-industra/statement-generator/outgoing-transactions',
                { transaction_number },
                { responseType: 'blob' }
            )
            .then((response) => {
                this.setState({
                    isLoading: false,
                });

                const { data } = response;

                download('statement.xml', data);
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    error: error.data,
                });
            });
    }

    render () {
        const { isLoading, error, transaction_number } = this.state;

        return (
            <Card title='Outgoing Transaction'>
                <form onSubmit={this.onFormSubmit}>
                    <Dimmer active={isLoading} loader>
                        <Card.Body>
                            <InputTextField
                                name='transaction_number'
                                title='Transaction Number'
                                value={transaction_number}
                                handleChange={this.onInputChange}
                                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                            />
                            <span>Number of the transaction in <b>processing</b> status</span>

                            <ApiError error={error} />
                        </Card.Body>
                        <Card.Footer className='text-center'>
                            <div className='d-flex'>
                                <Button type='submit' color='primary' className='ml-auto'>
                                    Generate Statement
                                </Button>
                            </div>
                        </Card.Footer>
                    </Dimmer>
                </form>
            </Card>
        );
    }
}

export default CreateOutgoingTransaction;
