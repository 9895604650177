import { Form } from 'tabler-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class WalletType extends Component {
    render () {
        const { onChange, value } = this.props;

        return (
            <Form.Select name='wallet_type' label='Wallet Type' value={value} onChange={onChange}>
                <option></option>
                <option key='mano_bank' value='mano_bank'>ManoBank</option>
                <option key='industra' value='industra'>Industra</option>
            </Form.Select>
        );
    }
}

WalletType.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

export default WalletType;
