import SiteWrapper from 'src/components/SiteWrapper';
import React, { useMemo, useState } from 'react';
import PaginatedFilteredList from 'src/components/Common/PaginatedFilteredList';
import { formatAmountAndCurrency, formatDateAndTime, objectToOptions, transactionStatus } from 'src/helpers';
import { FILTER_MASKS, PAYMENT_STATUSES } from 'src/constants';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const uri = '/admin-payment/payments';

const Payments = () => {
    const [items, setItems] = useState([]);

    const updateItems = (items) => {
        setItems(items);
    };

    const renderTransactionStatus = (item) => {
        if (item.original.error_text !== null) {
            return (
                <div data-tip={item.original.error_text}>
                    {transactionStatus(item.value)}
                    <span className='icon-fail-reason' />

                    <ReactTooltip className='react-tooltip' />
                </div>
            );
        }

        return transactionStatus(item.value);
    };

    const renderList = () => (
        <PaginatedFilteredList
            uri={uri}
            columns={columns}
            filterFields={filterFields}
            updateItems={updateItems}
            items={items}
            sortBy={'id'}
        />
    );

    const filterFields = useMemo(
        () => [
            {
                title: 'Status',
                name: 'status',
                type: 'select',
                options: objectToOptions(PAYMENT_STATUSES),
            },
            {
                title: 'From',
                name: 'created_at_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'To',
                name: 'created_at_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'External Id',
                name: 'external_id',
                type: 'text',
            },
            {
                title: 'External Transaction Id',
                name: 'external_transaction_id',
                type: 'text',
            },
        ],
        [],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Created At',
                accessor: 'created_at',
                sortable: false,
                Cell: (row) => formatDateAndTime(row.value),
            },
            {
                Header: 'Payment Method',
                accessor: 'payment_method_name',
                sortable: false,
            },
            {
                Header: 'Amount',
                accessor: 'amount',
                sortable: false,
                Cell: (row) => formatAmountAndCurrency(row.original.amount, row.original.currency),
            },
            {
                Header: 'Fee Amount',
                accessor: 'fee_amount',
                sortable: false,
                Cell: (row) => formatAmountAndCurrency(row.original.fee_amount, row.original.currency),
            },
            {
                Header: 'Status',
                accessor: 'status',
                sortable: false,
                Cell: (row) => renderTransactionStatus(row),
            },
            {
                Header: 'User',
                accessor: 'user_full_name',
                sortable: false,
                Cell: (row) =>
                    row.original.user_id !== null ? (
                        <Link to={{ pathname: `/users/${row.original.user_id}` }}>{row.original.user_full_name}</Link>
                    ) : (
                        <span>{row.original.user_full_name}</span>
                    ),
            },
            {
                Header: 'Transaction',
                accessor: 'transaction_id',
                sortable: false,
                Cell: (row) =>
                    row.original.transaction_id !== null ? (
                        <Link to={{ pathname: `/transactions/${row.original.transaction_id}` }}>{row.original.transaction_number}</Link>
                    ) : (
                        <span>-</span>
                    ),
            },
            {
                Header: 'External Id',
                accessor: 'external_id',
                sortable: false,
                width: 300,
            },
            {
                Header: 'External Transaction Id',
                accessor: 'external_transaction_id',
                sortable: false,
                width: 300,
            },
            {
                Header: 'Updated At',
                accessor: 'updated_at',
                sortable: false,
                Cell: (row) => formatDateAndTime(row.value),
            },
        ],
        [],
    );

    return  <SiteWrapper title='Payments'>{renderList()}</SiteWrapper>;
};

export default Payments;
