import React, { Component } from 'react';
import 'react-table/react-table.css';
import { Button, Card, Dimmer } from 'tabler-react';
import { createApiClient } from 'src/services/api-client';
import ApiError from '../../../Common/ApiError';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';
import { download } from 'src/services/download';

class CreateIncomingTransaction extends Component {
    constructor (props) {
        super(props);

        this.state = {
            error: null,
            isLoading: false,
            amount: '200.95',
            currency: 'eur',
            sender_name: 'Vaskulas Lavaskulas',
            sender_account: 'NL06INGB1157530303',
            details: 'Wallet Reference: W-866-276',
        };

        this.api = createApiClient();

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange (event) {
        const { name, value } = event.target;

        this.setState({
            [name]: value,
        });
    }

    async onFormSubmit (event) {
        this.setState({
            isLoading: true,
        });

        event.preventDefault();

        const { amount, currency, sender_name, sender_account, details } = this.state;

        this.api
            .post(
                '/admin-industra/statement-generator/incoming-transactions',
                { amount, currency, sender_name, sender_account, details },
                { responseType: 'blob' }
            )
            .then((response) => {
                this.setState({
                    isLoading: false,
                });

                const { data } = response;

                download('statement.xml', data);
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    error: error.data,
                });
            });
    }

    render () {
        const { isLoading, error, amount, currency, sender_name, sender_account, details } = this.state;

        return (
            <Card title='Incoming Transaction'>
                <form onSubmit={this.onFormSubmit}>
                    <Dimmer active={isLoading} loader>
                        <Card.Body>
                            <InputTextField
                                name='amount'
                                title='Amount'
                                value={amount}
                                handleChange={this.onInputChange}
                                isNumber={true}
                            />
                            <InputTextField
                                name='currency'
                                title='Currency'
                                value={currency}
                                handleChange={this.onInputChange}
                                className={'disabled'}
                            />
                            <InputTextField
                                name='sender_name'
                                title='Sender Name'
                                value={sender_name}
                                handleChange={this.onInputChange}
                            />
                            <InputTextField
                                name='sender_account'
                                title='Sendeer Account'
                                value={sender_account}
                                handleChange={this.onInputChange}
                            />
                            <InputTextField
                                name='details'
                                title='Details'
                                value={details}
                                handleChange={this.onInputChange}
                            />

                            <ApiError error={error} />
                        </Card.Body>
                        <Card.Footer className='text-center'>
                            <div className='d-flex'>
                                <Button type='submit' color='primary' className='ml-auto'>
                                    Generate Statement
                                </Button>
                            </div>
                        </Card.Footer>
                    </Dimmer>
                </form>
            </Card>
        );
    }
}

export default CreateIncomingTransaction;
