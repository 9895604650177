import React from 'react';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { Badge } from 'tabler-react';
import upperFirst from 'lodash/upperFirst';
import { TRANSACTION_STATUSES, TRANSACTION_TYPES, CHARGE_STATUS, TRANSACTION_DIRECTIONS } from './constants';

export const formatDate = (date) => {
    if (typeof date !== 'number') {
        return '-';
    }

    const offset = new Date().getTimezoneOffset();

    return moment
        .unix(date + 60 * -offset)
        .tz('UTC')
        .format('YYYY-MM-DD');
};

export const formatDateAndTime = (date) => {
    if (typeof date !== 'number') {
        return '-';
    }

    const offset = new Date().getTimezoneOffset();

    return moment
        .unix(date + 60 * -offset)
        .tz('UTC')
        .format('YYYY-MM-DD HH:mm:ss');
};

export const boolToYesNo = (bool) => (bool ? 'Yes' : 'No');

export const userStatusColor = (is_verified) => {
    if (typeof is_verified !== 'boolean') {
        return 'secondary';
    }

    return is_verified ? 'success' : 'danger';
};

export const userStatus = (is_verified) => (
    <>
        {is_verified ? (
            <Badge color={userStatusColor(is_verified)}>Verified</Badge>
        ) : (
            <Badge color={userStatusColor(is_verified)}>Not verified</Badge>
        )}
    </>
);

export const walletStatusColor = (is_active) => {
    if (typeof is_active !== 'boolean') {
        return 'secondary';
    }

    return is_active ? 'success' : 'danger';
};

export const walletStatus = (is_active) => (
    <>
        {is_active ? (
            <Badge color={walletStatusColor(is_active)}>Active</Badge>
        ) : (
            <Badge color={walletStatusColor(is_active)}>Inactive</Badge>
        )}
    </>
);

export const userVerificationStatusColor = (status) => {
    let color = 'secondary';

    switch (status) {
        case 'pending':
            color = 'warning';
            break;
        case 'verified':
            color = 'success';
            break;
        case 'declined':
            color = 'danger';
            break;
    }

    return color;
};

export const userVerificationStatus = (status) => (
    <Badge color={userVerificationStatusColor(status)}>{status ? capitalize(status) : ''}</Badge>
);

export const transactionStatusColor = (status) => {
    let color = 'secondary';

    switch (status) {
        case 'created':
            color = 'secondary';
            break;
        case 'pending':
            color = 'secondary';
            break;
        case 'manual_approval':
            color = 'warning';
            break;
        case 'processing':
            color = 'info';
            break;
        case 'canceled':
            color = 'danger';
            break;
        case 'refunded':
            color = 'danger';
            break;
        case 'failed':
            color = 'danger';
            break;
        case 'completed':
            color = 'success';
            break;
    }

    return color;
};

export const transactionStatus = (status) => (
    <Badge color={transactionStatusColor(status)}>
        {TRANSACTION_STATUSES.hasOwnProperty(status) ? TRANSACTION_STATUSES[status] : upperFirst(status)}
    </Badge>
);

export const statementTransactionStatusColor = (status) => {
    let color = 'warning';

    switch (status) {
        case 'allocated':
            color = 'success';
            break;
        case 'unrelated':
            color = 'warning';
            break;
        case 'refunded':
            color = 'danger';
            break;
        case 'imported':
            color = 'info';
            break;
    }

    return color;
};

export const statementTransactionRelation = (status) => (
    <Badge color={statementTransactionStatusColor(status)}>
        {upperFirst(status)}
    </Badge>
);

export const chargeStatus = (status) => (
    <Badge color={status === 'completed' ? 'success' : 'secondary'}>
        {CHARGE_STATUS.hasOwnProperty(status) ? CHARGE_STATUS[status] : upperFirst(status)}
    </Badge>
);

export const transactionType = (type) => (TRANSACTION_TYPES.hasOwnProperty(type) ? TRANSACTION_TYPES[type] : type);

export const transactionDirection = (type) => (TRANSACTION_DIRECTIONS.hasOwnProperty(type) ? TRANSACTION_DIRECTIONS[type] : type);

export const formatAmountAndCurrency = (amount, currency) => {
    if (amount === null) {
        return '-';
    }

    return `${formatAmount(amount)} ${currency ? currency.toUpperCase() : null}`;
};

export const capitalize = (string) => {
    if (typeof string !== 'string') {
        return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const titleCase = (text) => text.replace(/(^\w|\s\w)/g, (text) => text.toUpperCase());

export const uppercase = (string) => {
    if (typeof string === 'string') {
        return string.toUpperCase();
    }

    return string;
};

export const objectToOptions = (items) => {
    const options = [];
    Object.entries(items).map(([key, value]) => {
        options.push({
            title: value,
            value: key,
        });

        return value;
    });

    return options;
};

export const mapToOptions = (items) => {
    const options = [];

    Object.entries(items).map(([key, value]) => {
        options.push({
            title: key,
            value,
        });

        return value;
    });

    return options;
};

export const getFileExtension = (fileName) =>
    fileName
        .split('.')
        .pop()
        .toLowerCase();

export const formatAmount = (amount) => numeral(amount).format('0,0.00');
