import SiteWrapper from 'src/components/SiteWrapper';
import React, { useCallback, useMemo, useState } from 'react';
import PaginatedFilteredList from 'src/components/Common/PaginatedFilteredList';
import { FILTER_MASKS } from 'src/constants';
import UploadReportModal from 'src/components/Content/Payments/UploadReportModal';
import { formatDateAndTime } from 'src/helpers';
import { Button } from 'tabler-react';
import DownloadButton from 'src/components/Common/Buttons/DownloadButton';

const uri = '/admin-payment/settlement-reports';
const exportConfig = { uri: '/admin-payment/settlement-reports/download', filename: 'settlement-reports.csv' };

const SettlementReports = () => {
    const [items, setItems] = useState([]);
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);

    const updateItems = (items) => {
        setItems(items);
    };

    const uploadReport = async () => {
        setUploadModalOpen(true);
    };

    const setUploadModalVisible = useCallback(
        (value) => () => {
            setUploadModalOpen(value);
        },
        [],
    );

    const renderList = () => (
        <>
            <UploadReportModal
                modalIsOpen={isUploadModalOpen}
                onModalClose={setUploadModalVisible(false)}
            />
            <PaginatedFilteredList
                uri={uri}
                columns={columns}
                filterFields={filterFields}
                updateItems={updateItems}
                items={items}
                exportConfig={exportConfig}
                sortBy={'psr.id'}
                showUploadButton={true}
                upload={uploadReport}
            />
        </>
    );

    const filterFields = useMemo(
        () => [
            {
                title: 'From',
                name: 'created_at_from',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
            {
                title: 'To',
                name: 'created_at_to',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
            },
        ],
        [],
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Created At',
                accessor: 'created_at',
                sortable: false,
                Cell: (row) => formatDateAndTime(row.value),
            },
            {
                Header: 'Provider',
                accessor: 'provider',
                sortable: false,
            },
            {
                Header: 'Currency',
                accessor: 'currency',
                sortable: false,
            },
            {
                Header: 'Date',
                accessor: 'date',
                sortable: false,
                width: 300,
            },
            {
                Header: 'Total Paid',
                accessor: 'total_paid',
                sortable: false,
            },
            {
                Header: 'Provider Fees',
                accessor: 'provider_fees',
                sortable: false,
            },
            {
                Header: 'Total Received',
                accessor: 'total_received',
                sortable: false,
            },
            {
                Header: 'Own Fees',
                accessor: 'own_fees',
                sortable: false,
            },
            {
                Header: 'Total E-Money',
                accessor: 'total_emoney',
                sortable: false,
            },
            {
                Header: 'Report',
                id: 'report',
                sortable: false,
                Cell: (row) => (
                    <Button.List>
                        <DownloadButton downloadUrl={`admin-payment/settlement-reports/${row.original.id}/download`} />
                    </Button.List>
                ),
            },
        ],
        [],
    );

    return  <SiteWrapper title='Settlement Reports'>{renderList()}</SiteWrapper>;
};

export default SettlementReports;
