import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SiteWrapper from '../../../SiteWrapper';
import { Button, Card, Dimmer, Grid } from 'tabler-react';
import { createApiClient as api } from '../../../../services/api-client';
import PropTypes from 'prop-types';
import ApiError from '../../../Common/ApiError';
import { navigate } from 'src/actions/navigate';
import { toast } from 'react-toastify';
import Country from './FormFields/Country';
import Currency from './FormFields/Currency';
import Direction from './FormFields/Direction';
import TransactionType from './FormFields/TransactionType';
import Type from './FormFields/Type';
import TypeFields from './FormFields/TypeFields';
import isString from 'lodash/isString';
import { buildSubmitData, resolveFeeTypeByAmount } from './services/transaction-fee';
import { INTERNAL_TRANSFER, EXTERNAL_TRANSFER, OUTGOING_TRANSACTION } from 'src/constants';
import InputTextField from '../../../Common/FilterFormFields/InputTextField';
import ReturnFee from 'src/components/Content/Fees/TransactionFees/FormFields/ReturnFee';
import WalletType from 'src/components/Content/Fees/TransactionFees/FormFields/WalletType';
import FeeWallets from 'src/components/Content/Fees/TransactionFees/FormFields/FeeWallets';

class TransactionFeeEdit extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isLoading: false,
            error: null,
            type: 'fixed',
            fee: {
                name: '',
                direction: OUTGOING_TRANSACTION,
                transaction_type: INTERNAL_TRANSFER,
                amount: '0',
                minimum: '0',
                maximum: '0',
                percent: '0',
                currency: '',
                countries: [],
                wallet_type: '',
                fee_wallet_relation_id: '',
            },
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount () {
        this.fetchTransactionFeeConfiguration();
    }

    fetchTransactionFeeConfiguration () {
        const { id } = this.props.match.params;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .get(`/admin-fee/transaction-fee-configurations/${id}`)
            .then((data) => {
                this.setState({
                    fee: {
                        name: data.data.name,
                        direction: data.data.direction,
                        transaction_type: data.data.transaction_type,
                        amount: data.data.amount === null ? '0' : data.data.amount,
                        minimum: data.data.minimum === null ? '' : data.data.minimum,
                        maximum: data.data.maximum === null ? '' : data.data.maximum,
                        percent: data.data.percent === null ? '0' : data.data.percent,
                        currency: data.data.currency,
                        countries: data.data.countries,
                        return_fee: data.data.return_fee,
                        wallet_type: data.data.wallet_type,
                        fee_wallet_relation_id: data.data.fee_wallet_relation ? data.data.fee_wallet_relation.id.toString() : null,
                    },
                    type: resolveFeeTypeByAmount(data.data.amount, data.data.percent),
                    isLoading: false,
                });
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    handleSubmit (event) {
        event.preventDefault();

        const { id } = this.props.match.params;
        const { fee, type } = this.state;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .put(`admin-fee/transaction-fee-configurations/${id}`, buildSubmitData(fee, type))
            .then(() => {
                toast.success('Updated successful');
                this.props.navigate('/fee/transaction-fees');
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    handleSubmitCreate = (event) => {
        event.preventDefault();

        const { fee, type } = this.state;

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .post('admin-fee/transaction-fee-configurations/', buildSubmitData(fee, type))
            .then(() => {
                toast.success('Create successful');
                this.props.navigate('/fee/transaction-fees');
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    };

    handleChange (event) {
        const { target } = event;

        this.setState((prevState) => ({
            fee: {
                ...prevState.fee,
                [target.name]: target.type === 'checkbox'
                    ? target.checked
                    : target.value.length > 0 || !isString(target.value) ? target.value : '',
            },
        }));
    }

    changeType = (event) => {
        const { target } = event;

        this.setState({
            [target.name]: target.value,
        });
    };

    render () {
        const { fee, error, type, isLoading } = this.state;
        const { navigate } = this.props;

        return (
            <SiteWrapper title='Edit transaction fee'>
                <Grid.Row>
                    <Grid.Col md={6} sm={8} xs={12}>
                        <Card>
                            <form onSubmit={this.handleSubmit}>
                                <Dimmer active={isLoading} loader>
                                    <Card.Body>
                                        <Type onChange={this.changeType} value={type} />

                                        <InputTextField name='name' title='Name' value={fee.name} handleChange={this.handleChange} />

                                        <Direction onChange={this.handleChange} value={fee.direction} />

                                        <TransactionType onChange={this.handleChange} value={fee.transaction_type} />

                                        <TypeFields onChange={this.handleChange} fee={fee} type={type} />

                                        <Currency onChange={this.handleChange} value={fee.currency} />

                                        {fee.transaction_type === EXTERNAL_TRANSFER && (
                                            <Country onChange={this.handleChange} value={fee.countries} />
                                        )}

                                        <ReturnFee onChange={this.handleChange} value={fee.return_fee} />

                                        <WalletType onChange={this.handleChange} value={fee.wallet_type}/>

                                        <FeeWallets onChange={this.handleChange} value={fee.fee_wallet_relation_id} />

                                        <ApiError error={error} />
                                    </Card.Body>
                                    <Card.Footer className='d-flex'>
                                        <Button link onClick={() => navigate('/fee/transaction-fees')}>
                                            Cancel
                                        </Button>
                                        <Button type='submit' color='primary' className='ml-auto' onClick={this.handleSubmit}>
                                            Update
                                        </Button>
                                        <Button type='submit' color='primary' className='ml-auto' onClick={this.handleSubmitCreate}>
                                            Create new
                                        </Button>
                                    </Card.Footer>
                                </Dimmer>
                            </form>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </SiteWrapper>
        );
    }
}

TransactionFeeEdit.propTypes = {
    match: PropTypes.object,
    navigate: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
        },
        dispatch,
    );

export default connect(null, mapDispatchToProps)(TransactionFeeEdit);
