import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ProviderSelect = ({ onChange }) => (
    <div className='d-flex'>
        <select className='w-100' name='provider' onChange={onChange}>
            <option />
            <option value='industra'>
                Industra
            </option>
        </select>
    </div>
);

ProviderSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default memo(ProviderSelect);
