import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'tabler-react';
import Transactions from 'src/components/Content/Transactions';

const TransactionList = ({ walletId, isTransactionListOpen, toggleTransactionList }) => {
    const predefinedFilters = {
        wallet_id: walletId,
    };

    return (
        <Card>
            <Card.Header className='filterHeader'>
                <div onClick={toggleTransactionList}>
                    <span>Transactions</span>
                    <i className={`fe fe-chevrons-${isTransactionListOpen ? 'up' : 'down'}`} />
                </div>
            </Card.Header>
            {isTransactionListOpen && <Transactions renderOnlyList={true} predefinedFilters={predefinedFilters}/>}
        </Card>
    );
};

TransactionList.propTypes = {
    walletId: PropTypes.number.isRequired,
    isTransactionListOpen: PropTypes.bool.isRequired,
    toggleTransactionList: PropTypes.func.isRequired,
};

export default TransactionList;
