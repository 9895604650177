import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import { createApiClient as api } from '../../services/api-client';
import { Card, Grid, Text, Nav, Button } from 'tabler-react';
import isUndefined from 'lodash/isUndefined';
import FilterForm from './FilterForm';
import ReactTable from 'react-table';
import ApiError from './ApiError';
import { download } from '../../services/download';
import moment from 'moment';
import { capitalize } from '../../helpers';
import { FILTER_KEYS_WITH_TYPE_NUMBER } from '../../constants';

class PaginatedFilteredList extends Component {
    constructor(props) {
        super(props);

        const filter = {};

        if (!isUndefined(props.filterFields)) {
            props.filterFields.forEach((field) => {
                if (!isUndefined(field.value)) {
                    filter[field.name] = field.value;
                }
            });
        }

        this.state = {
            isLoading: false,
            error: null,
            currencies: [],
            pages: 0,
            resultCount: 0,
            isFirstLoad: true,

            table: {
                page: 0,
                pageSize: 25,
                sorted: [
                    {
                        id: !isUndefined(props.sortBy) ? props.sortBy : 'created_at',
                        desc: props.sortByDirectionDescending,
                    },
                ],
            },
            filter,
        };

        this.changeFilter = this.changeFilter.bind(this);
        this.changeTable = this.changeTable.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.syncData = this.syncData.bind(this);
        this.uploadModal = this.uploadModal.bind(this);
    }

    changeTable(state) {
        const { filter, isFirstLoad } = this.state;
        const { page, pageSize, sorted } = state;

        if (!isFirstLoad) {
            this.updateUrl({
                ...filter,
                page,
                pageSize,
            });
        }

        this.setState(
            {
                table: {
                    page,
                    pageSize,
                    sorted,
                },
            },
            this.fetchData,
        );
    }

    changeFilter(name, value) {
        const { filter } = this.state;

        this.setState({
            filter: {
                ...filter,
                [name]: value,
            },
        });
    }

    componentDidMount() {
        const { table, filter } = this.state;
        const urlQuery = location.search.substr(1);

        this.setState({ isFirstLoad: false });

        if (urlQuery === '') {
            return;
        }

        const parsedURLQueryParams = queryString.parse(urlQuery);
        table.page = parsedURLQueryParams.page ? Number(parsedURLQueryParams.page) : table.page;
        table.pageSize = parsedURLQueryParams.pageSize ? Number(parsedURLQueryParams.pageSize) : table.pageSize;
        delete parsedURLQueryParams.page;
        delete parsedURLQueryParams.pageSize;

        this.setState({
            table: {
                ...table,
            },
            filter: {
                ...filter,
                ...parsedURLQueryParams,
            },
        });
    }

    updateUrl(params) {
        const newUrl = `${window.location.origin}${window.location.pathname}?${queryString.stringify(params)}`;

        window.history.replaceState({ path: newUrl }, '', newUrl);
    }

    onSubmit() {
        const { table, filter } = this.state;
        const { page, pageSize } = table;

        this.updateUrl({ ...filter, page, pageSize });

        this.setState(
            {
                table: {
                    ...table,
                    page: 0,
                },
            },
            this.fetchData,
        );
    }

    async downloadCsv() {
        const { uri, filename } = this.props.exportConfig;

        const { filter } = this.getFilterParams();

        const timezone = moment.tz.guess();

        const { data } = await api().get(uri, {
            params: { timezone, filter },
            responseType: 'blob',
        });

        download(filename, data);
    }

    fetchData() {
        const { updateItems, updateColumns, updateMetadata, uri, columns, autoMapOptions } = this.props;

        const params = this.getFilterParams();

        this.setState({
            isLoading: true,
            error: null,
        });

        api()
            .get(uri, { params })
            .then((data) => {
                this.setState({
                    pages: data.data.pages,
                    isLoading: false,
                    resultCount: data.data.count,
                });

                updateItems(data.data.items);

                if (data.data.meta_data && updateMetadata) {
                    updateMetadata(data.data.meta_data);
                }

                if (!data.data.items.length > 0 || columns.length > 0) {
                    return;
                }

                const mappedColumns = Object.keys(data.data.items[0]).reduce((acc, column) => {
                    if (autoMapOptions.exclude && autoMapOptions.exclude.includes(column)) {
                        return acc;
                    }

                    const formattedHeader = capitalize(column.split('_').join(' '));
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { custom = {}, include, ...options } = autoMapOptions;

                    return [
                        ...acc,
                        {
                            Header: formattedHeader,
                            accessor: column,
                            sortable: false,
                            ...options,
                            ...custom[column],
                        },
                    ];
                }, []);

                const extraColumns = autoMapOptions.include;
                if (!extraColumns || !extraColumns.length > 0) {
                    updateColumns(mappedColumns);
                    return;
                }

                const allColumns = [...mappedColumns];
                for (const extraColumn of extraColumns) {
                    const { indexKey, ...options } = extraColumn;

                    if (indexKey !== undefined && indexKey >= 0 && allColumns.length > indexKey) {
                        allColumns.splice(indexKey, 0, options);
                        continue;
                    }
                    allColumns.push(options);
                }

                updateColumns(allColumns);
            })
            .catch((data) => {
                this.setState({
                    error: data.data,
                    isLoading: false,
                });
            });
    }

    syncData() {
        this.props.sync(this.fetchData);
    }

    uploadModal() {
        this.props.upload();
    }

    removeFalsyValuesAndParseKeysWithTypeNumber(filter) {
        return Object.entries(filter).reduce((acc, [key, value]) => {
            if (value === null || value === undefined || value === '') {
                return acc;
            } else if (FILTER_KEYS_WITH_TYPE_NUMBER[key]) {
                return { ...acc, [key]: parseInt(value, 10) };
            }

            return { ...acc, [key]: value };
        }, {});
    }

    getFilterParams() {
        const { table, filter } = this.state;

        const [order] = table.sorted;

        const modifiedFilter = {
            ...filter,
            ...this.props.predefinedFilters,
        };

        return {
            limit: table.pageSize,
            page: table.page + 1,
            order_by: order.id,
            order_by_direction: order.id !== null ? (order.desc === true ? 'DESC' : 'ASC') : null,
            filter: this.removeFalsyValuesAndParseKeysWithTypeNumber(modifiedFilter),
        };
    }

    renderTabs = () => {
        const { tabsOptions, onTabChange, uri } = this.props;

        if (!tabsOptions || !tabsOptions.length) {
            return null;
        }

        const handleTabChange = (tab) => () => {
            onTabChange(tab.value);
        };

        return (
            <Nav className='ml-3'>
                {tabsOptions.map((tab, idx) => (
                    <Nav.Item key={idx} active={uri === tab.value} value={tab.label} onClick={handleTabChange(tab)} />
                ))}
            </Nav>
        );
    };

    render() {
        const { pages, resultCount, table, isLoading, error } = this.state;

        const {
            filterFields,
            columns,
            items,
            parentError,
            showFilter,
            exportConfig,
            listHeader,
            renderOnlyList,
            tableClassNames,
            showTableHeader,
            showSyncButton,
            showUploadButton,
        } = this.props;

        const parsedQueryFromURL = queryString.parse(location.search);

        return (
            <>
                {filterFields && !renderOnlyList && (
                    <Grid.Row>
                        <Grid.Col>
                            <FilterForm
                                fields={filterFields}
                                error={error}
                                submitCallback={this.onSubmit}
                                onFilterChange={this.changeFilter}
                                csvCallback={exportConfig ? this.downloadCsv : undefined}
                                show={showFilter}
                            />
                        </Grid.Col>
                    </Grid.Row>
                )}

                {showTableHeader && (
                    <Grid.Row alignItems='end' className='mb-2'>
                        {listHeader}
                        {this.renderTabs()}
                        <Grid.Col className='text-right'>
                            <Text className='m-3 d-inline-block' size='small' right='true' color='secondary'>
                                {`Results found: ${resultCount}`}
                            </Text>
                            <Button onClick={this.fetchData} className='mr-3 ml-3' size='sm' color='primary' icon='rotate-ccw'>
                                Refresh List
                            </Button>

                            {renderOnlyList && (
                                <Button onClick={this.downloadCsv} className='mr-3 ml-3' size='sm' color='secondary' icon='download'>
                                    Download .csv
                                </Button>
                            )}

                            {showSyncButton && (
                                <Button onClick={this.syncData} className='mr-3 ml-3' size='sm' color='warning' icon='download'>
                                    Sync Statement
                                </Button>
                            )}
                            {showUploadButton && (
                                <Button onClick={this.uploadModal} className='mr-3 ml-3' size='sm' color='warning' icon='upload'>
                                    Upload
                                </Button>
                            )}
                        </Grid.Col>
                    </Grid.Row>
                )}
                <Grid.Row>
                    <Grid.Col>
                        <Card className={tableClassNames}>
                            <Card.Body>
                                <ApiError error={parentError} />

                                <ReactTable
                                    manual
                                    data={items}
                                    pages={pages}
                                    page={table.page}
                                    loading={isLoading || this.props.isLoading}
                                    columns={columns}
                                    defaultPageSize={parsedQueryFromURL.pageSize ? Number(parsedQueryFromURL.pageSize) : table.pageSize}
                                    onFetchData={this.changeTable}
                                    className='-striped -highlight'
                                    multiSort={false}
                                    defaultSorted={table.sorted}
                                    minRows={2}
                                />
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </>
        );
    }
}

PaginatedFilteredList.propTypes = {
    uri: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    filterFields: PropTypes.array,
    items: PropTypes.array.isRequired,
    updateItems: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    parentError: PropTypes.object,
    sortBy: PropTypes.string,
    showFilter: PropTypes.bool,
    exportConfig: PropTypes.object,
    listHeader: PropTypes.node,
    autoMapOptions: PropTypes.object,
    updateColumns: PropTypes.func,
    sortByDirectionDescending: PropTypes.bool,
    tabsOptions: PropTypes.array,
    onTabChange: PropTypes.func,
    renderOnlyList: PropTypes.bool,
    tableClassNames: PropTypes.string,
    showTableHeader: PropTypes.bool,
    updateMetadata: PropTypes.func,
    showSyncButton: PropTypes.bool,
    sync: PropTypes.func,
    showUploadButton: PropTypes.bool,
    upload: PropTypes.func,
    predefinedFilters: PropTypes.object,
};

PaginatedFilteredList.defaultProps = {
    isLoading: false,
    parentError: null,
    showFilter: false,
    listHeader: null,
    renderOnlyList: false,
    tabsOptions: [],
    sortByDirectionDescending: true,
    autoMapOptions: {
        custom: {},
        sortable: false,
        include: [],
        exclude: [],
    },
    showTableHeader: true,
    showSyncButton: false,
    predefinedFilters: {},
};

export default PaginatedFilteredList;
